<template>
    <div>
        <!-- <div class="d-flex text-white justify-content-end align-items-center px-2 pt-2  h4" style="gap:10px">
            Filter Data <i class="bx bxs-filter-alt" style="color: #FFAD32;"></i>
        </div> -->

        <h3 class="text-center text-white font-weight-bold h3 mt-3 mb-0">Mongrela Pet Sitters</h3>
        <!-- <p class="px-2 text-white text-justify mt-1">
            be a part of our community and help us care for pets when their owners can't. Join our community of dedicated pet sitters, where your love for animals makes a real difference. By being a Mongrela Pet Sitter, you're not just caring for pets; you're enriching their lives with love and attention. Join us in providing top-notch care for our furry friends.        
        </p> -->

        <hr style="border-color: #FFAD32;" />

        <div class="d-flex align-items-center px-2 my-1" style="gap:10px">
            <div class="w-50">
                <select class="form-control" v-model="filter.pet_type" placeholder="Select Date" style="background: transparent;border:2px solid #E3A230;" >
                    <option selected :value="null">
                        Pet Type
                    </option>
                    <option value="dog">Dogs</option>
                    <option value="cat">Cats</option>
                </select>            
            </div>
            <div class="w-50">
                <select class="form-control" v-model="filter.pet_breed" placeholder="Select Date" style="background: transparent;border:2px solid #E3A230;" >
                    <option selected :value="null">
                        Pet Size
                    </option>
                    <option value="all" selected>All Size</option>
                    <option value="small">Small</option>
                    <option value="average">Average</option>
                    <option value="large">Large</option>
                </select>   
                <!-- <input class="form-control" placeholder="Pet Breed" v-model="filter.pet_breed" style="background: transparent;color: white;border:2px solid #E3A230;" > -->
            </div>
        </div>

        <div v-for="item in walker_list">
            <router-link :to="`/bussiness/walker/${item.user_id}`" class="d-flex align-items-center p-1 px-2" style="gap:10px 20px">
                <div>
                    <img :src="item.owner.user_picture ? item.owner.user_picture : default_img"
                        class="rounded-circle" style="height:80px;width:80px;border: 2px solid white" />
                </div>
                <div class="flex-fill">
                    <h3 class="text-white h3 mb-0" style="margin: .05em 0 !important;">{{item.owner.user_name}}</h3>
                    <table>
                        <tr>
                            <td>
                                <h3 class="text-white h5 mb-0" style="margin: .05em 0 !important;font-weight: normal;">Rating</h3> 
                            </td>
                            <td class="text-center" style="width:20px">
                                <h3 class="text-white h5 mb-0" style="margin: .05em 0 !important;font-weight: normal;">:</h3> 
                            </td>
                            <td>
                                <h3 class="text-white h5 mb-0" style="margin: .05em 0 !important;font-weight: normal;">Coming Soon</h3> 
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <h3 class="text-white h5 mb-0" style="margin: .05em 0 !important;font-weight: normal;">Pet Type</h3> 
                            </td>
                            <td class="text-center" style="width:20px">
                                <h3 class="text-white h5 mb-0" style="margin: .05em 0 !important;font-weight: normal;">:</h3> 
                            </td>
                            <td>
                                <h3 class="text-white h5 mb-0" style="margin: .05em 0 !important;font-weight: normal;">{{item.preferred_pet}}</h3> 
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <h3 class="text-white h5 mb-0" style="margin: .05em 0 !important;font-weight: normal;">Pet Size</h3> 
                            </td>
                            <td class="text-center" style="width:20px">
                                <h3 class="text-white h5 mb-0" style="margin: .05em 0 !important;font-weight: normal;">:</h3> 
                            </td>
                            <td>
                                <h3 class="text-white h5 mb-0" style="margin: .05em 0 !important;font-weight: normal;">{{item.preferred_breed}}</h3> 
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <h3 class="text-white h5 mb-0" style="margin: .05em 0 !important;font-weight: normal;">Availability</h3> 
                            </td>
                            <td class="text-center" style="width:20px">
                                <h3 class="text-white h5 mb-0" style="margin: .05em 0 !important;font-weight: normal;">:</h3> 
                            </td>
                            <td>
                                <h3 v-if="item.availability == 'y'" class="text-success h5 mb-0" style="margin: .05em 0 !important;font-weight: normal;">Avaialble</h3> 
                                <h3 v-else class="text-danger h5 mb-0" style="margin: .05em 0 !important;font-weight: normal;">Avaialble</h3> 
                            </td>
                        </tr>
                    </table> 
                </div> 
            </router-link>
        </div>
    </div>

    <!-- <div class="d-flex flex-fill w-100">
        <div class="m-auto d-flex flex-column align-items-center">
            <img class="" style="width:200px;" src="@/static/logo.png" />
            <label class="text-white h2">Page Under Maintenance</label>
        </div>
    </div> -->

</template>

<script>

import store from '@/store'

export default {
    computed: {
        walker_list(){
            return store.state.walker.WALKER_LIST
        },
    },
    watch: {
        filter: {
            handler: function(val){
                store.dispatch('walker/GetWalkerList', val)
            }, deep: true, immediate: true
        }
    },
    mounted(){
    },
    data(){
        return {
            default_img : 'https://w7.pngwing.com/pngs/129/292/png-transparent-female-avatar-girl-face-woman-user-flat-classy-users-icon.png',
            filter: {
                pet_breed: null,
                pet_type: null
            }
        }
    }
}

</script>

<style>
    select.form-control, select.form-control:active {
        color:white !important
    }
    select.form-control option, select.form-control:active option {
        color:white !important;
        background-color: #1B628C !important;
    }    
</style>